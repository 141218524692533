<template>
    <div class="createBillMsg scrolling">
        <div class="zwnr">
            <div class="flex title">
                <div class="fwxx">发文信息</div>
                <div><a-button class="font" @click="clear_form" type="primary" ghost size="small">清空</a-button></div>
            </div>
            <div class="gapLine"></div>
            <div style="margin-top: 20px">
                <a-form :form="form" class="">
                    <a-form-item label="公文标题" :labelCol="labelCol" :wrapperCol="wrapperCol" has-feedback>
                        <a-input
                            placeholder="请输入"
                            v-decorator="['biaoti', { rules: [{ required: true, message: '请输入公文标题！' }] }]"
                        />
                    </a-form-item>
                    <a-form-item label="发文字号" :labelCol="labelCol" :wrapperCol="wrapperCol">
                        <a-input placeholder="请输入" v-decorator="['documentNumber', { initialValue: '默认' }]" />
                    </a-form-item>
                    <a-form-item label="公文密级程度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                        <a-select placeholder="请选择" v-decorator="['miji', {}]">
                            <a-select-option
                                v-for="(item, index) in flow_secrecy_degree"
                                :key="index"
                                :value="item.code"
                                >{{ item.value }}</a-select-option
                            >
                        </a-select>
                    </a-form-item>
                    <a-form-item label="公文紧急程度" :labelCol="labelCol" :wrapperCol="wrapperCol">
                        <a-select placeholder="请选择" v-decorator="['jjcd', {}]">
                            <a-select-option
                                v-for="(item, index) in flow_urgency_degree"
                                :key="index"
                                :value="item.code"
                                >{{ item.value }}</a-select-option
                            >
                        </a-select>
                    </a-form-item>

                    <a-form-item label="选择分类" :labelCol="labelCol" :wrapperCol="wrapperCol">
                        <a-select placeholder="请选择" v-decorator="['xzfn', {}]">
                            <a-select-option v-for="(item, index) in flow_file_type" :key="index" :value="item.code">{{
                                item.value
                            }}</a-select-option>
                        </a-select>
                    </a-form-item>

                    <a-form-item label="外部会搞单位/备注" :labelCol="labelCol" :wrapperCol="wrapperCol">
                        <a-input placeholder="请输入" v-decorator="['outDraftDesc', { initialValue: '默认' }]" />
                    </a-form-item>
                    <div class="gapLine"></div>
                    <a-row :gutter="24">
                        <a-col :span="12">
                            <a-form-item label="拟稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input
                                    v-show="false"
                                    v-decorator="['ngdw_list', { rules: [{ required: true, message: '请选择！' }] }]"
                                />
                                <div class="flex0 flex-warp">
                                    <div v-for="(o, i) in ngdw_list" :key="'ngdw_list' + i" class="my-item">
                                        <a-tag class="tags flex0">
                                            <OpenData
                                                :type="o.type"
                                                :openid="o.userId || o.id"
                                                :corpid="o.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="ngdw_list.splice(i, 1)" />
                                        </a-tag>
                                    </div>
                                    <a-button
                                        type="primary"
                                        ghost
                                        slot="suffix"
                                        icon="user"
                                        @click="showSelectDialog('ngdw_list')"
                                    ></a-button>
                                </div>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item label="外部会稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input v-show="false" v-decorator="['wbhgdw_list', {}]" />
                                <div class="flex0 flex-warp">
                                    <div v-for="(o, i) in wbhgdw_list" :key="'wbhgdw_list' + i" class="my-item">
                                        <a-tag class="tags flex0">
                                            <OpenData
                                                :type="o.type"
                                                :openid="o.userId || o.id"
                                                :corpid="o.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="wbhgdw_list.splice(i, 1)" />
                                        </a-tag>
                                    </div>
                                    <a-button
                                        type="primary"
                                        ghost
                                        slot="suffix"
                                        icon="user"
                                        @click="showSelectDialog('wbhgdw_list')"
                                    ></a-button>
                                </div>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item label="会稿单位/人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input v-show="false" v-decorator="['hgdw_list', {}]" />
                                <div class="flex0 flex-warp">
                                    <div v-for="(o, i) in hgdw_list" :key="'hgdw_list' + i" class="my-item">
                                        <a-tag class="tags flex0">
                                            <OpenData
                                                :type="o.type"
                                                :openid="o.userId || o.id"
                                                :corpid="o.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="hgdw_list.splice(i, 1)" />
                                        </a-tag>
                                    </div>
                                    <a-button
                                        type="primary"
                                        ghost
                                        slot="suffix"
                                        icon="user"
                                        @click="showSelectDialog('hgdw_list')"
                                    ></a-button>
                                </div>
                            </a-form-item>
                        </a-col>

                        <a-col :span="12">
                            <a-form-item label="核稿人" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input v-show="false" v-decorator="['checkIds', {}]" />
                                <div class="flex0 flex-warp">
                                    <div v-for="(o, i) in checkIds" :key="'checkIds' + i" class="my-item">
                                        <a-tag class="tags flex0">
                                            <OpenData
                                                :type="o.type"
                                                :openid="o.userId || o.id"
                                                :corpid="o.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="checkIds.splice(i, 1)" />
                                        </a-tag>
                                    </div>
                                    <a-button
                                        type="primary"
                                        ghost
                                        slot="suffix"
                                        icon="user"
                                        @click="showSelectDialog('checkIds')"
                                    ></a-button>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="主送" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input
                                    v-show="false"
                                    v-decorator="['mainIds', { rules: [{ required: true, message: '请选择！' }] }]"
                                />
                                <div class="flex0 flex-warp">
                                    <div v-for="(o, i) in mainIds" :key="'mainIds' + i" class="my-item">
                                        <a-tag class="tags flex0">
                                            <OpenData
                                                :type="o.type"
                                                :openid="o.userId || o.id"
                                                :corpid="o.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="mainIds.splice(i, 1)" />
                                        </a-tag>
                                    </div>
                                    <a-button
                                        type="primary"
                                        ghost
                                        slot="suffix"
                                        icon="user"
                                        @click="showSelectDialog('mainIds')"
                                    ></a-button>
                                </div>
                            </a-form-item>
                        </a-col>
                        <a-col :span="12">
                            <a-form-item label="抄送" :labelCol="labelCol" :wrapperCol="wrapperCol">
                                <a-input v-show="false" v-decorator="['sendIds', {}]" />
                                <div class="flex0 flex-warp">
                                    <div v-for="(o, i) in sendIds" :key="'sendIds' + i" class="my-item">
                                        <a-tag class="tags flex0">
                                            <OpenData
                                                :type="o.type"
                                                :openid="o.userId || o.id"
                                                :corpid="o.corpId"
                                            ></OpenData>
                                            <a-button type="link" icon="close" @click="sendIds.splice(i, 1)" />
                                        </a-tag>
                                    </div>
                                    <a-button
                                        type="primary"
                                        ghost
                                        slot="suffix"
                                        icon="user"
                                        @click="showSelectDialog('sendIds')"
                                    ></a-button>
                                </div>
                            </a-form-item>
                        </a-col>
                    </a-row>
                </a-form>
            </div>
        </div>
        <div class="bg-gray"></div>
        <div class="zwnr" style="padding-top: 15px">
            <div class="flex title">
                <div class="fwxx">附件内容</div>
                <div><a-button class="font" type="primary" ghost @click="empty" size="small">清空</a-button></div>
            </div>
            <div class="gapLine"></div>
            <div class="margin-tt">
                <upload :fileIds.sync="fileIds" ref="upload" />
            </div>
            <div class="bts">
                <a-popconfirm
                    placement="topRight"
                    title="发布公文后公文不能修改，确定发送公文！"
                    @confirm="() => handleSubmit(null, 2)"
                >
                    <a-button type="primary"> 立即发布 </a-button>
                </a-popconfirm>
                <a-button type="primary" @click="handleSubmit($event, 1)" ghost> 存为草稿 </a-button>
            </div>
        </div>
    </div>
</template>
<script>
import { documentBillAdd, documentBillEdit } from '@/api/modular/fileStream/documentManage'
import upload from '@/views/fileStream/documentManage/upload.vue'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import selectPersonAndDepart, {
    dealResult,
    getUserListMsg,
} from '@/views/fileStream/documentManage/weixin/selectPerson'
import minxin from '../../components/minxin'
export default {
    components: {
        OpenData,
        upload,
    },
    mixins: [minxin],
    data() {
        return {
            labelCol: {
                sm: { span: 5 },
            },
            wrapperCol: {
                sm: { span: 19 },
            },
            form: this.$form.createForm(this),
            fileIds: [],
            content: '',
            ngdw_list: [],
            hgdw_list: [],
            wbhgdw_list: [], // 外部会稿单位
            checkIds: [],
            mainIds: [],
            sendIds: [],
        }
    },
    methods: {
        clearMessage() {
            this.clear_form()
            this.empty()
            // this.$refs.editor.msg = ''
        },
        showSelectDialog(key) {
            this.$emit('showSelectDialog', key)
            // this.$refs.selectPerson.open({ key })
        },
        objectEquail(obj1, obj2) {
            return JSON.stringify(obj1) === JSON.stringify(obj2)
        },
        setLabelPerson({ result, key }) {
            result.forEach((element) => {
                if (!this[key].some((j) => this.objectEquail(j, element))) {
                    this[key].push(element)
                }
            })
            this.reassignData()
        },
        // 重新赋值表单数据
        reassignData() {
            this.form.setFieldsValue({
                mainIds: this.mainIds,
                ngdw_list: this.ngdw_list,
            })
        },
        get_info(query) {
            this.form_id = query.id
            const id = this.form_id
            if (id) {
                this.apiBillDetail(id).then((res) => {
                    const {
                        documentNumber,
                        outDraftDesc,
                        name,
                        urgencyDegree,
                        secrecyDegree,
                        draftId,
                        fileType,
                        documentFileList,
                        content,
                        meetId,
                        outDraftId,
                    } = res.data
                    const form = {
                        biaoti: name,
                        jjcd: urgencyDegree ? String(urgencyDegree) : '',
                        miji: secrecyDegree ? String(secrecyDegree) : '',
                        userId: draftId,
                        xzfn: fileType ? String(fileType) : '',
                        fileIds: documentFileList,
                        content,
                        hgdw_list: meetId,
                        wbhgdw_list: outDraftId,
                        checkIds: res.data.checkIds,
                        mainIds: res.data.mainIds,
                        sendIds: res.data.sendIds,
                        documentNumber,
                        outDraftDesc,
                    }
                    //
                    this.form.setFieldsValue(this.delEmptKey(form))
                    //
                    this.ngdw_list = this.formatDraftId(draftId)
                    this.hgdw_list = this.formatDraftId(meetId)
                    this.wbhgdw_list = this.formatDraftId(outDraftId)
                    this.checkIds = this.formatDraftId(res.data.checkIds)
                    this.mainIds = this.formatDraftId(res.data.mainIds)
                    this.sendIds = this.formatDraftId(res.data.sendIds)
                    //
                    // this.$refs.editor.msg = content
                    this.fileIds = documentFileList
                })
            }
        },

        clear_form() {
            this.form.resetFields()
            this.ngdw_list = []
            this.hgdw_list = []
            this.wbhgdw_list = []
            this.checkIds = []
            this.mainIds = []
            this.sendIds = []
        },
        empty() {
            this.$refs.upload.empty()
        },
        delEmptKey(obj) {
            const objCopy = { ...obj }
            const keys = Object.keys(objCopy)
            for (const index of keys) {
                if (!objCopy[index]) delete objCopy[index]
            }
            return objCopy
        },
        handleSubmit(e, status) {
            // eslint-disable-next-line camelcase
            const { filter, content, fileIds, ngdw_list, hgdw_list, wbhgdw_list, checkIds, mainIds, sendIds } = this
            let func = documentBillAdd
            const id = this.form_id
            if (id) {
                func = documentBillEdit
            }
            console.log('form+++++++++')
            this.form.validateFields((err, values) => {
                if (!err) {
                    const obj = {
                        name: values.biaoti,
                        serialNumber : values.serialNumber,
                        urgencyDegree: Number(values.jjcd) || '',
                        secrecyDegree: Number(values.miji) || '',
                        draftId: filter(ngdw_list),
                        fileType: Number(values.xzfn) || '',
                        fileIds,
                        content,
                        documentNumber: values.documentNumber,
                        outDraftDesc: values.outDraftDesc,
                        meetId: filter(hgdw_list),
                        outDraftId: filter(wbhgdw_list),
                        documentType: 2,
                        checkIds: filter(checkIds),
                        mainIds: filter(mainIds),
                        sendIds: filter(sendIds),
                        status,
                    }
                    if (id) {
                        obj.id = id
                    }
                    func(this.delEmptKey(obj)).then((res) => {
                        if (!res.success) {
                            return
                        }
                        this.$message.success(res.message)
                        this.clearMessage()
                        this.$emit('changeTab', status === 1 ? 3 : 1)
                    })
                }
            })
        },
        formatDraftId(id) {
            if (!id) return []
            return JSON.parse(id).map((j) => ({ ...j, id: j.userId || j.departmentId }))
        },
        choose_Persons(key) {
            const {
                selectedOpenUserIds,
                selectedCorpGroupUserIds,
                selectedDepartmentIds,
                selectedCorpGroupDepartmentIds,
            } = getUserListMsg(key, null, this[key])
            selectPersonAndDepart({
                selectedOpenUserIds,
                selectedCorpGroupDepartmentIds,
                selectedDepartmentIds,
                selectedCorpGroupUserIds,
            })
                .then((res) => {
                    const { result, resPart } = dealResult(res)
                    const finalyValue = [...result, ...resPart]
                    this.$set(this, key, finalyValue)
                    this.form.setFieldsValue({ [key]: finalyValue.map((j) => j.userId || j.id).join() })
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        getIdKey(type) {
            return type === 'departmentName' ? 'departmentId' : 'userId'
        },
        filter(ids) {
            return JSON.stringify(
                ids.map(({ corpId, userId, id, type }) => ({
                    [this.getIdKey(type)]: userId || id,
                    corpId,
                    type,
                }))
            )
        },
    },
}
</script>
<style lang="less" scoped>
.createBillMsg {
    padding: 9px 12px;
    .flex {
        display: flex;
        justify-content: space-between;
    }
    .flex-warp {
        flex-wrap: wrap;
    }
    .flex0 {
        display: flex;
        align-items: center;
    }
    .font {
        font-size: 16px;
    }
    .gapLine {
        border-top: 1px dashed rgba(59, 154, 250, 0.6);
        margin: 10px 0;
    }
    .line {
        height: 1px;
        border: 1px dashed #3b9afa;
        display: block;
        width: 100%;
        margin-top: 10px;
    }
    .fwxx {
        font-size: 18px;
        font-weight: bold;
        color: #3e3d3d;
        line-height: 1;
    }
    .fwxx::before {
        content: '';
        width: 4px;
        height: 20px;
        background: #3b9afa;
        display: inline-block;
        position: relative;
        top: 3px;
        margin-right: 10px;
    }
    .body {
        margin: auto;
        background: #fff;
    }
    ::v-deep .ant-form-item-label {
        text-align: left;
    }
    .bg-gray {
        height: 10px;
        background: #ebedf0;
    }
    .schj {
        width: 310px;
        height: 58px;
        background: #f9f9f9;
        border: 1px solid #e6e6e6;
    }
    .margin-tt {
        margin-top: 20px;
        text-align: center;
    }
    .bts {
        text-align: center;
        padding: 57px 0 64px 0;
        button {
            font-size: 16px;
            margin: 0 10px;
        }
    }
    .my-item {
        display: inline-block;
    }
}
</style>
